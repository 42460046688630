@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  /* scroll-behavior: smooth; */
  overflow-x: clip;
}

/* remove overflow-x on landing page */
html,
body {
  overflow-x: clip;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  padding-right: 0 !important;
}

h1 {
  font-size: unset;
  letter-spacing: -0.1rem;
  font-weight: 700;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
a {
  color: inherit;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

.seo {
  font-weight: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Styles for Wordpress Blogpost CTA's */
/* try-yoodli-for-free-cta-button */
.button_wrapper_id_3613 {
  width: 100%;
}
.button_wrapper_id_3613 .try-yoodli-for-free-cta-button,
.button_wrapper_id_3613 .try-yoodli-for-free-cta-button:hover {
  font-weight: 600;
  color: white !important;
  background: #6966fe;
  padding: 16px 32px;
  transition: all 0.3s;
}

/* Gradient BG */
@property --gradientBGColor1 {
  syntax: "<color>";
  initial-value: #1da9e6;
  inherits: false;
}

@property --gradientBGColor2 {
  syntax: "<color>";
  initial-value: #6966fe;
  inherits: false;
}

.gradientBg {
  background: linear-gradient(
    90deg,
    var(--gradientBGColor1),
    var(--gradientBGColor2) 82%
  ) !important;
  transition:
    --gradientBGColor1 0.3s,
    --gradientBGColor2 0.3s;
}

.button_wrapper_id_3613 .try-yoodli-for-free-cta-button,
.button_wrapper_id_3613 .try-yoodli-for-free-cta-button:hover {
  transition:
    --gradientBGColor1 0.3s,
    --gradientBGColor2 0.3s;
  background: linear-gradient(90deg, var(--gradientBGColor1), var(--gradientBGColor2) 82%);
}

.gradientBg:hover {
  --gradientBGColor1: #2a6dbd !important;
  --gradientBGColor2: #4240b3 !important;
}
/* End Gradient BG */

html.noscroll,
body.noscroll {
  overflow-y: hidden !important;
}

/* global fix for LazyLoadImage component wrapper 
  https://github.com/Aljullu/react-lazy-load-image-component/issues/33#issuecomment-495933506
*/
.lazy-load-image-background {
  display: block !important;
}

/* Font preloading */
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

:root {
  --font-poppins: "Poppins", system-ui, -apple-system, sans-serif;
}

body {
  font-family: var(--font-poppins);
}

h1 {
  font-family:
    var(--font-poppins),
    system-ui,
    -apple-system,
    sans-serif !important;
  font-weight: 700 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.Nav_menuTitle__WUDC0 {
  white-space: nowrap;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */ /* IE10+/Edge */
  user-select: none;
}

.Nav_menuTitle__WUDC0:after {
  content: "";
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  pointer-events: none;
  border-radius: 5px;
  background: transparent;
  transition:
    width 0.2s ease,
    background-color 0.2s ease;
  position: relative;
  top: 8px;
}
.Nav_menuOpen__YklWK:after {
  width: 100%;
  background: #6966fd;
  margin-bottom: 3px;
}

/* Mobile Nav Icon */
.Nav_navIconWrapper__wE0qZ {
  width: 48px;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
}
.Nav_navIcon__4Ds_D {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 32px;
  height: 32px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.Nav_navIcon__4Ds_D span {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  opacity: 1;
  /* these styles applied in js to dynamically set duration */
  /* -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; */
}
.Nav_navIcon__4Ds_D span:nth-child(1) {
  width: 85%;
}
.Nav_navIcon__4Ds_D span:nth-child(2) {
  width: 40%;
}
.Nav_navIcon__4Ds_D.Nav_open__czamJ span:nth-child(1),
.Nav_navIcon__4Ds_D.Nav_open__czamJ span:nth-child(2) {
  width: 100%;
}
/* End Mobile Nav Icon */
.Nav_navDrawer__V87Nm:after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;
}

.Nav_navDrawerOpen__Z4Ue6:after {
  opacity: 1;
}


.menuTitle {
  white-space: nowrap;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

.menuTitle:after {
  content: "";
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  pointer-events: none;
  border-radius: 5px;
  background: transparent;
  transition:
    width 0.2s ease,
    background-color 0.2s ease;
  position: relative;
  top: 8px;
}
.menuOpen:after {
  width: 100%;
  background: #6966fd;
  margin-bottom: 3px;
}

/* Mobile Nav Icon */
.navIconWrapper {
  width: 48px;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
}
.navIcon {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 32px;
  height: 32px;
  position: relative;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.navIcon span {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  opacity: 1;
  /* these styles applied in js to dynamically set duration */
  /* -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; */
}
.navIcon span:nth-child(1) {
  width: 85%;
}
.navIcon span:nth-child(2) {
  width: 40%;
}
.navIcon.open span:nth-child(1),
.navIcon.open span:nth-child(2) {
  width: 100%;
}
/* End Mobile Nav Icon */
.navDrawer:after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;
}

.navDrawerOpen:after {
  opacity: 1;
}
